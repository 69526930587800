import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import SmallCTA from '../../components/CTA-small-platforms';
import Feature from '../../components/Feature';
import FeatureCloud from '../../components/FeatureCloud';
import FeatureVideo from '../../components/FeatureVideo';
import Socials from '../../components/Socials';
import Link from '../../components/link';

const styles = css`
  .featureList {
    padding: 0rem 0 5rem;
    text-align: center;

    & > h2 {
      padding: 5rem 2rem 0rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 3vw, 40px);
      letter-spacing: -0.015em;
      color: #0a1530;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
    & > p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;

      padding-left: calc(1rem + env(safe-area-inset-left, 0));
      padding-right: calc(1rem + env(safe-area-inset-right, 0));
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & li {
      color: #000;
    }
  }

  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }

  .badge {
    vertical-align: middle;
    font-size: 0.8rem;
    line-height: 0.8rem;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 3px 5px 1px;
  }
  div.socials {
    margin: 1rem auto;
    padding: 0 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 38rem;
    font-weight: 400;
    color: #2c82ad;
    max-width: 80rem;
    margin: 2rem auto 0rem;
    font-size: 1rem;

    @media (max-width: 80rem) {
      padding: 0 2rem;
    }

    & svg {
      fill: currentcolor;
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
    }
  }
`;

// css breakpoints
// test everywhere
// meta info
// social media previews
// broken links
// headings
// color contrast
// accessibility issues
// debug tools
// disabled images
// layout issues
// unneeded scrollbars

function FeatureList() {
  return (
    <StaticQuery
      query={photoquery}
      render={(data) => (
        <section className={styles.featureList}>
          <FeatureVideo screenshot={data.syncedactions} type="light" large brow="Synced interactions">
            <h3>
              Test everything everywhere.
              <br />
              Just once.
            </h3>
            <p>
              Test your forms, menus and other interactions across your site on all devices in one go.{' '}
              <strong>Everything you do</strong> is synced between devices.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.metadata1} type="light" large brow="Meta panel">
            <h3>Stop looking through devtools for your meta data.</h3>
            <p>
              All your meta data in a single overview so you can quickly spot issues. Highlights missing values,
              duplicates, too long and too short content.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.metadata2} type="light" brow="Meta panel">
            <h3>Pixel-perfect social media previews.</h3>

            <Socials />
            <p>
              Pixel-perfect previews of social media sharing cards, for Twitter, Facebook, Slack, LinkedIn, Discord,
              Telegram, Bluesky and Google Search. In dark and light mode.
            </p>

            <p>
              <Link to="/social-media-previews/">Deep dive into our social media previews</Link>
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.links} type="light" brow="Outline panel">
            <h3>Your browser tells you which links are broken.</h3>
            <p>
              Automatically checks all the links on your page for problems. If they're broken or have other issues,
              we'll let you know.
            </p>
          </FeatureVideo>

          <SmallCTA />

          <FeatureVideo screenshot={data.headers} type="light" brow="Outline panel">
            <h3>The perfect structure.</h3>
            <p>Headings, Focus order, Document outline. Get an overview of your site and get the perfect structure.</p>
          </FeatureVideo>

          <Feature screenshot={data.contrast} type="light" full brow="Debug tools">
            <h3>Improve color contrasts</h3>
            <p>
              Find all elements that don't have enough contrast using the real text and background colors. We'll suggest
              improved colors too!
            </p>
          </Feature>

          <Feature gatsbyImg={data.a11ytest} type="light" full brow="Accessibility panel">
            <h3>Automated accessibility test</h3>
            <p>
              Test your pages with Polypane's accessibility panel to get an overview of issues and ways to improve them.
            </p>
          </Feature>

          <FeatureVideo screenshot={data.debugtools} type="light" brow="Debug tools">
            <h3>40 different debug tools</h3>
            <p>Test your site with simulators like color blindness, tunnelvision and dyslexia.</p>
            <p>Debug layout issues like horizontal overflow detection, disabled images and unneeded scrollbars.</p>
          </FeatureVideo>
          <h2>And much more</h2>
          <FeatureCloud type="light" />
        </section>
      )}
    />
  );
}

export default FeatureList;

const photoquery = graphql`
  query siteQualityPageQuery {
    metadata1: file(relativePath: { eq: "videos/marketers/metadata1.mp4" }) {
      publicURL
    }
    metadata2: file(relativePath: { eq: "videos/marketers/metadata2.mp4" }) {
      publicURL
    }
    links: file(relativePath: { eq: "videos/marketers/links.mp4" }) {
      publicURL
    }
    headers: file(relativePath: { eq: "videos/marketers/headers.mp4" }) {
      publicURL
    }
    debugtools: file(relativePath: { eq: "videos/marketers/debugtools.mp4" }) {
      publicURL
    }
    devicewall: file(relativePath: { eq: "videos/marketers/devicewall.mp4" }) {
      publicURL
    }
    syncedactions: file(relativePath: { eq: "videos/marketers/syncedactions.mp4" }) {
      publicURL
    }
    debugtools: file(relativePath: { eq: "videos/marketers/debugtools.mp4" }) {
      publicURL
    }
    metadata2: file(relativePath: { eq: "videos/marketers/metadata2.mp4" }) {
      publicURL
    }
    screenshots: file(relativePath: { eq: "videos/marketers/screenshot.mp4" }) {
      publicURL
    }
    darkmode: file(relativePath: { eq: "videos/marketers/darkmode.mp4" }) {
      publicURL
    }
    links: file(relativePath: { eq: "videos/marketers/links.mp4" }) {
      publicURL
    }
    liveVideo: file(relativePath: { eq: "videos/livecss.mp4" }) {
      publicURL
    }
    deviceVideo: file(relativePath: { eq: "videos/devices.mp4" }) {
      publicURL
    }
    breakpointVideo: file(relativePath: { eq: "videos/breakpoints.mp4" }) {
      publicURL
    }
    browseVideo: file(relativePath: { eq: "videos/browse.mp4" }) {
      publicURL
    }
    devtoolsVideo: file(relativePath: { eq: "videos/devtools.mp4" }) {
      publicURL
    }
    liveReloadVideo: file(relativePath: { eq: "videos/live-reload.mp4" }) {
      publicURL
    }
    handoffVideo: file(relativePath: { eq: "videos/handoff.mp4" }) {
      publicURL
    }
    zoomVideo: file(relativePath: { eq: "videos/zooming.mp4" }) {
      publicURL
    }
    modesVideo: file(relativePath: { eq: "videos/modes.mp4" }) {
      publicURL
    }
    referenceVideo: file(relativePath: { eq: "videos/reference.mp4" }) {
      publicURL
    }
    overlaysVideo: file(relativePath: { eq: "videos/overlays.mp4" }) {
      publicURL
    }
    contrast: file(relativePath: { eq: "images/image12.gif" }) {
      publicURL
    }
    a11ytest: file(relativePath: { eq: "images/a11ytest.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;
