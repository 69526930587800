import { css } from 'astroturf';
import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Layout from '../../layout';
import FeatureList from './_FeatureList';

const styles = css`
  .icon {
    max-width: 100%;
    width: 10rem;
    --white: #fff;
    margin-bottom: 2rem;
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="The perfect browser for Accessibility" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <svg className={styles.icon} viewBox="0 0 111 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="sqgradient" gradientTransform="rotate(45)">
                <stop offset="0" stopColor="rgb(44, 130, 173)" stopOpacity="1" />

                <stop offset="1" stopColor="rgb(103, 178, 111)" stopOpacity="1" />
              </linearGradient>
            </defs>
            <rect width="111" height="60" rx="10" fill="url(#sqgradient)" />
            <rect x="6" y="6" width="49" height="48" rx="6" fill="var(--white)" />
            <rect x="16" y="17" width="29" height="3" fill="url(#gradient)" />
            <rect x="16" y="25" width="13" height="3" fill="url(#gradient)" />
            <rect x="16" y="33" width="21" height="3" fill="url(#gradient)" />
            <rect x="16" y="41" width="16" height="3" fill="url(#gradient)" />
            <rect x="68" y="17" width="29" height="3" fill="var(--white)" />
            <rect x="68" y="25" width="13" height="3" fill="var(--white)" />
            <rect x="68" y="33" width="21" height="3" fill="var(--white)" />
            <rect x="68" y="41" width="16" height="3" fill="var(--white)" />
          </svg>
          <h1>Your site in peak performance</h1>
          <h2>
            There's a million things you need to get right to give users the best experience. From responsive design to
            meta tags to page speed.
          </h2>

          <p>Polypane has the tools to improve your site quality.</p>
        </PageHeader>
        <FeatureList />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
