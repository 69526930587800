import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .featureCloud {
    display: flex;
    font-size: calc(14px + 0.25vw);
    font-weight: 500;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 1rem auto 2rem;
    padding: 0 2rem;
    line-height: 1;
    gap: 1rem 1.5rem;

    color: rgba(255, 255, 255, 0.6);

    &.light {
      color: rgba(0, 0, 0, 0.6);

      & div.highlight {
        color: #000;
      }
    }

    & div {
      line-height: 1.5;
      &.highlight {
        font-size: 1.15em;
        color: #fff;
      }
    }
  }
`;

function FeatureCloud(props) {
  return (
    <div className={[styles.featureCloud, props.type ? styles[props.type] : ''].join(' ')}>
      <div className={styles.highlight}>Unlimited Viewports</div>
      <div>Scroll Syncing</div>
      <div className={styles.highlight}>Hover Syncing</div>
      <div>Grid Overlays</div>
      <div className={styles.highlight}>Unified Console</div>
      <div>Keyboard Syncing</div>
      <div className={styles.highlight}>JSON Viewer</div>
      <div className={styles.highlight}>Device Presets</div>
      <div>Freeform Resizing</div>
      <div className={styles.highlight}>Horizontal Overflow Detection</div>
      <div>Developer Tools</div>
      <div className={styles.highlight}>Pixel-perfect Social Media Previews</div>
      <div>Different Layout Modes</div>
      <div className={styles.highlight}>Device Emulation</div>
      <div className={styles.highlight}>Handoff Support</div>
      <div>Live Reloading</div>
      <div className={styles.highlight}>Accessibility Audits</div>
      <div className={styles.highlight}>Custom Sessions</div>
      <div>Page Outlines</div>
      <div className={styles.highlight}>Live CSS Editing</div>
      <div>Color Blindness Simulators</div>
      <div className={styles.highlight}>Full Page Screenshots</div>
      <div>Visual Design Diffing</div>
      <div>Meta Data Overview</div>
      <div className={styles.highlight}>CSS Breakpoint Detection</div>
      <div>Overview Screenshots</div>
      <div className={styles.highlight}>Unified Element Inspector</div>
      <div>Touch Emulation</div>
      <div className={styles.highlight}>Automated Form Testing</div>
      <div>Screenshot Editor</div>
      <div className={styles.highlight}>Web Vitals Inspector</div>
      <div>Color Picker</div>
      <div className={styles.highlight}>Browser Extensions</div>
      <div className={styles.highlight}>Broken Link Checking</div>
      <div>Share Links</div>
      <div>Custom Headers</div>
      <div className={styles.highlight}>Dark Mode</div>
      <div>Workspaces</div>
      <div className={styles.highlight}>&hellip;and more added monthly!</div>
    </div>
  );
}

export default FeatureCloud;
