import React from 'react';
import CTA from './CTA-small';
import Platforms from './Platforms';

const SmallCTA = () => (
  <div>
    <CTA />

    <small>
      <Platforms />
    </small>
  </div>
);

export default SmallCTA;
